<template>
    <main class="blog-index-page">
        <div :class="info.page.content ? 'lg:pb-[20px]' : ''">
            <h1 class="blog-index-page__title">{{ info.page.h1 }}</h1>

            <div class="blog-index-page__content" v-if="info.page.content" v-html="info.page.content"></div>
        </div>

        <section>
            <ul class="blog-index-page__blog-posts">
                <li v-for="(data, index) in info.page.data.featured_articles" :key="index" class="post">
                    <BCardBasic :post="data" :category="data.category.slug" :blog-tag="data.category.name" />
                </li>
            </ul>
        </section>
    </main>
</template>

<script lang="ts" setup>
import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const { data: responseData, error } = await useAsyncData('blog-index-data', () => {
    return $fetch<Api.Responses.Pages.Blog>(endpoints.pages.blog.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL: baseUrl,
    }).catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}
const info = responseData.value

const currentDates = currentDate()

const {
    public: {
        variant: {
            metaData: { siteName, siteOrigin },
        },
    },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [info.page.title || '', info.page.description || ''],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: siteOrigin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: siteOrigin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.blog-index-page {
    @apply container mb-12 space-y-5;

    &__title {
        @apply my-[21px] text-center text-[32px] font-semibold text-gray-800;
    }

    &__content {
        @apply text-lg;
    }

    &__blog-posts {
        @apply grid grid-cols-1 gap-x-5 gap-y-10 xs:grid-cols-2 md:grid-cols-3;
    }
}
</style>
